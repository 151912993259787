
import { Component, OnInit, Testability } from '@angular/core';
import { Model, SurveyNG, StylesManager } from "survey-angular";
// import { SurveyReaderService } from '../../../../_services/SurveyReader.service';
// import "survey-core/survey.i18n";
// import { surveyLocalization } from '../../../../node_modules/survey-angular/ts3.4/survey.angular';
import { FormControl, FormGroup, MaxLengthValidator, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
// import { EmployeeService } from 'src/app/_services/employee.service';
// import { Employee } from 'src/app/_models/employee';
import { environment } from 'src/environments/environment';
import { AlertifyService } from '../../Services/AlertifyService.service';
import { SurveyReaderService } from '../../Services/SurveyReader.service';
import { isNull } from '@angular/compiler/src/output/output_ast';


const surveyJson = {
  elements: [{
    name: "FirstName",
    title: "Enter your first name:",
    type: "text"
  }, {
    name: "LastName",
    title: "Enter your last name:",
    type: "text"
  }]
};



@Component({
  selector: 'app-SurveyViewer',
  templateUrl: './SurveyViewer.component.html',
  styleUrls: ['./SurveyViewer.component.css']
})

export class SurveyViewerComponent implements OnInit {


//#region  variables

  title = 'My First Survey';
  // employeemainDataForSurvey:SurveyForOthersEmpData;
  // logedInEmpData:logedInEmpData;

  EmployeeNameEn:string="";
  EmployeeNameAr:string="";

  employeeSapNumber=null;

  // SurveyPassword="Testpass";

  survey:Model= new Model(surveyJson);

  // SurveyForOthersFlag:boolean=false;
  // TakeSurveyOnceFlag:boolean=false;

  // hasEmailFlag=true;
  showSurvey=false;
  SurveyAvailable=false;
  surveySelector:string = "";
  submitBy:string = "";

//#endregion

//#region constructor

  constructor(private alertService:AlertifyService,
              private http:HttpClient,
              private route:ActivatedRoute,
              private translate: TranslateService,
              private SurveyService:SurveyReaderService,
              private router:Router)
  {
    // this.surveyName=environment.surveyUniqueName;

  }

//#endregion

//#region  ngOnInit

  ngOnInit() {

      this.route.queryParams.subscribe((params) => {
        // this.employeeSapNumber = params["EsmSAPNumber"];
        this.surveySelector = params["Select"];
        this.submitBy = params["By"];
        debugger;
        if(this.surveySelector !== "" && this.surveySelector != undefined)
        {
          debugger;
          if(this.submitBy != "" && this.submitBy != undefined)
          {
          debugger;

            this.SurveyService.CheckSubmitedBefore(this.submitBy).subscribe((res:any) => {

              //check submitted before
              if(res.data == true)
              {
                this.SurveyAvailable = true;
                this.LoadSurvey();
              }
              else
              { this.SurveyAvailable = false;
                //Submited befor
                this.alertService.warning(res.message);
              }
            });
          }
          else
          {
            this.submitBy=null;
            this.SurveyAvailable = true;

            this.LoadSurvey();
          }
        }
        else
        {
          this.alertService.warning("There is no survey available.");
        }

      });//End of subscribe query params
    }

//#endregion

//#region other functions

LoadSurvey()
{
  this.SurveyService.GetSurvey(this.surveySelector).subscribe((res:any) => {
    var surveyBody  = JSON.stringify(res.data.survey);

    this.survey = new Model(surveyBody);
  
    // console.log("this.survey ==> " , this.survey);
    // console.log("JSON.stringify(res.data) ==>" , JSON.stringify(res.data.survey))
    this.survey.onComplete.add(survey => {

      this.SurveyService.submitAns(survey.data,this.surveySelector,"",this.submitBy).subscribe((res:any) => {
        debugger;
        console.log("res ==> " , res);
        if(!res.data)
        {
          this.alertService.error(res.message);
        }
        else
        {
          this.alertService.success(res.message);
          // redirect to new component complete submition
        }
      },error=>{
        debugger;
        this.alertService.error("Sorry, Something went wrong please submit answer again.");
        console.log("Error ==> ",error);
      });

    });
    SurveyNG.render("surveyContainer", { model: this.survey });
  })
}

//#endregion


}


