import { Component, OnInit } from '@angular/core';

declare function TestFunction():any;

@Component({
  selector: 'app-NoSurveySelected',
  templateUrl: './NoSurveySelected.component.html',
  styleUrls: ['./NoSurveySelected.component.css']
})
export class NoSurveySelectedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    TestFunction();
  }

}

