
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SurveyReaderService
// implements OnDestroy

{

  //#region Fields

  surveyBaseUrl = environment.surveyApiUrl;

  //#endregion

//#region Constructor

constructor(private http:HttpClient) { }

//#endregion

//#region  Actions

TakeSurvey(surveyUniqueName:string, employeeSapNumber:number,OtherEmplyeeSapNumber:number,language:string){
  debugger;
  let queryParams = new HttpParams();
  queryParams = queryParams.append("surveyUniqueName",surveyUniqueName);
  queryParams = queryParams.append("employeeSapNumber",employeeSapNumber.toString());

  if(OtherEmplyeeSapNumber != null)
  {
    queryParams = queryParams.append("OtherEmplyeeSapNumber",OtherEmplyeeSapNumber.toString())
  }
  queryParams = queryParams.append("language",language);

  // return this.http.get(this.surveyBaseUrl+"Survey/GetSurvey",{params:queryParams});
  // return this.http.get(this.surveyBaseUrl+"SurveyAnswers/TakeSurvey",{params:queryParams});
  return this.http.get(this.surveyBaseUrl+"SurveyAnswers/TakeSurvey/"+surveyUniqueName+"/"+employeeSapNumber+"/"+OtherEmplyeeSapNumber+"/"+language);
}

// checkSurveyLogin(EmpSapNumber:number,SurveyPassword:string)
// {
//   debugger;
//   return this.http.post(this.baseUrl+"Employees/CheckPasswordForSurvey",{EmpSapNumber,SurveyPassword});
// }

submitAns(obj:any, surveyUniqueName:string, EmployeeSapNumber:string, SubmitBy:string): Observable<any>
{
  debugger;
  if(EmployeeSapNumber == '')
  {EmployeeSapNumber = 'null'}
  
  return this.http.post(this.surveyBaseUrl+"SurveyAnswers/SubmitAnswers/"+surveyUniqueName+"/"+EmployeeSapNumber+"/"+null+"/"+SubmitBy,obj);
}

GetSurvey(surveyUniqueName:string , language:string='en')
{
  let queryParams = new HttpParams();
  queryParams = queryParams.append("surveyUniqueName",surveyUniqueName);
  queryParams = queryParams.append("language",language);
  debugger;
  return this.http.get(this.surveyBaseUrl+"Survey/GetSurvey/",{params:queryParams});
}

CheckSubmitedBefore(submitBy:string)
{
  let queryParams = new HttpParams();

  queryParams = queryParams.append("submitBy",submitBy);

  return this.http.get(this.surveyBaseUrl+"Survey/CheckSubmitedBefore",{params:queryParams});
}

//#endregion

}

// ngOnDestroy(): void {
//   //Called once, before the instance is destroyed.
//   //Add 'implements OnDestroy' to the class.
//   window.localStorage.removeItem("SurveyForOthersFlag");
// }
