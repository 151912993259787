import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SurveyViewerComponent } from './Components/SurveyViewer/SurveyViewer.component';
import { NoSurveySelectedComponent } from './Components/NoSurveySelected/NoSurveySelected.component';

const routes: Routes = [
  {path: 'SurveyViewer', component:SurveyViewerComponent  },
  {path: '', component:SurveyViewerComponent  },
  {path: 'NoSurveySelected', component:NoSurveySelectedComponent  },
  {path: "**", component:NoSurveySelectedComponent  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
